import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { changeLocation } from '../../../../state/session/session.actions';
import { Modals } from '../../../../core/constants';
import AddressInformationModal from './AddressInformationModal';
import { revertAddress } from '../../../../state/addressInformationForm/addressForm.actions';

function mapStateToProps(state: AppState) {
  return {
    isMobile: state.platformParams.isMobile,
    isRTL: state.platformParams.isRTL,
    isFormValid: state.addressForm.isFormValid,
    isMultiLocation: state.session.isMultiLocation,
    isLoadingLocationFromServer: state.session.isLoadingLocationFromServer,
    isLoadingLocationsSettingsFromServer: state.session.isLoadingLocationsSettingsFromServer,
    isAddressSelectionModalOpen: Boolean(state.session.modals.find((m) => m.modal === Modals.ADDRESS_SELECTION)),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      changeLocation,
      revertAddress,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressInformationModal);
