import React, { useEffect } from 'react';
import _ from 'lodash';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Link, useLocation } from 'react-router-dom';
import {
  Address,
  DispatchInfo,
  DisplayableOrderItem,
  Restaurant,
  VirtualDispatchType,
} from '@wix/restaurants-client-logic';
import styles from './AddressInformationView.scss';
import dataHooks from '../../data-hooks';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/statics/assets/icons/Error.svg';
import AddressInformationSummary from '../AddressInformation/AddressInformationSummary';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import Text from '../../core-components/Text';
import { Trans, useBi, useExperiments, useTranslation } from 'yoshi-flow-editor-runtime';
import {
  SetDeliveryAddressFieldPayload,
  SetErrorVisibilityPayload,
  ToggleAllErrorsPayload,
} from '../../../../state/addressInformationForm/addressForm.actions.types';
import Button from '../Button';
import { DeliveryFormField } from '../../../../state/addressInformationForm/addressForm.reducer';
import { getErrorKey } from '../AddressInformation/AddressInformation.helper';
import { OpenModalPayload, SaveAddressToServerPayload } from '../../../../state/session/session.actions.types';
import { Modals } from '../../../../core/constants';
import { DineInInfo } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import AddressInformationInput from '../AddressInformation/AddressInformationInput';
import { getAsapText } from '../DispatchTimeSelector/DispatchTimeSelector.helper';
import CommentInput from '../CommentInput';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';
import { convertToOloAddressMembersAddress } from '../../../../core/logic/addressLogic';

export interface AddressInformationViewProps {
  restaurant: Restaurant;
  dispatchTime: number;
  dispatchType: VirtualDispatchType;
  done?: boolean;
  collapsed?: boolean;
  index?: string;
  setDeliveryAddressFromForm: () => void;
  setDeliveryAddressAdditionalInfo: () => void;
  onSubmit: () => void;
  onEdit: () => void;
  address: Address;
  formattedAddressWithComment: string;
  setFieldError: (payload: SetErrorVisibilityPayload) => void;
  fieldsErrors: {
    addressLine2: boolean;
  };
  isCurbside: boolean;
  curbsideAdditionalInformation: string | undefined;
  saveStateToSessionStorage: () => void;
  toggleAllErrors: (payload: ToggleAllErrorsPayload) => void;
  selectedAddressOption?: Address;
  idealDeliveryArea?: DispatchInfo;
  errorOrderItem?: DisplayableOrderItem;
  timezone: string;
  errorsVisibility: Record<DeliveryFormField, boolean>;
  deliveryInfos: DispatchInfo[];
  describedby?: string;
  locale: string;
  openModal: (payload: OpenModalPayload) => void;
  saveAddressToServer: (address: SaveAddressToServerPayload) => void;
  initialTableNumber?: string;
  dineInInfo?: DineInInfo;
  setDeliveryAddressField: (payload: SetDeliveryAddressFieldPayload) => void;
  isMultiLocation: boolean;
  selectedAddressId?: string;
}

const AddressInformationView: React.FC<AddressInformationViewProps> = ({
  dispatchType,
  index = '0',
  done,
  collapsed,
  onEdit,
  address,
  onSubmit,
  restaurant,
  dispatchTime,
  setDeliveryAddressFromForm,
  setDeliveryAddressAdditionalInfo,
  isCurbside,
  curbsideAdditionalInformation,
  formattedAddressWithComment,
  toggleAllErrors,
  selectedAddressOption,
  initialTableNumber,
  fieldsErrors,
  setFieldError,
  idealDeliveryArea,
  errorOrderItem,
  timezone,
  errorsVisibility,
  deliveryInfos,
  describedby,
  locale,
  openModal,
  dineInInfo,
  setDeliveryAddressField,
  saveStateToSessionStorage,
  isMultiLocation,
  saveAddressToServer,
  selectedAddressId,
}) => {
  const experiments = useExperiments();
  const location = useLocation();
  const { t } = useTranslation();
  const biLogger = useBi();
  const { displayableAmountLeft, isMinOrderPriceMet } = useMinOrderPriceDetails();

  useEffect(() => {
    toggleAllErrors({ value: false });
  }, [location, toggleAllErrors]);

  const timing = dispatchTime
    ? new Intl.DateTimeFormat(locale.split('_')[0], {
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone,
      }).format(dispatchTime)
    : getAsapText({ deliveryInfos, timezone, dispatchType, t, idealDeliveryArea, locale });

  const idSuffix = Math.random();
  const addressInformationTitleId = `restaurants.address-information.title-${idSuffix}`;

  function handleSubmitTakeout() {}

  function handleSubmitDineIn() {}

  function handleSubmitDelivery(): 'error' | 'ok' {
    const isAptValid =
      Boolean(selectedAddressOption?.addressLine2) ||
      !experiments.experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery');

    toggleAllErrors({ value: true });

    if (!isAptValid) {
      setFieldErrorAndBi({ errorType: 'addressLine2' });
      return 'error';
    }

    return 'ok';
  }

  function handleSubmit() {
    switch (dispatchType) {
      case 'takeout':
        handleSubmitTakeout();
        break;
      case 'delivery':
        const result = handleSubmitDelivery();
        if (result === 'error') {
          return;
        }
        if (selectedAddressOption && selectedAddressId) {
          const isAddressChanged =
            address.label !== selectedAddressOption.label ||
            address.comment !== selectedAddressOption.comment ||
            address.addressLine2 !== selectedAddressOption.addressLine2;

          isAddressChanged &&
            saveAddressToServer({
              address: convertToOloAddressMembersAddress(selectedAddressOption),
              addressId: selectedAddressId,
              setAsDefault: false,
            });
        }
        break;
      case 'dine-in':
        handleSubmitDineIn();
        break;
      default:
        return;
    }

    if (!isMultiLocation) {
      setDeliveryAddressFromForm();
    } else {
      setDeliveryAddressAdditionalInfo();
    }

    onSubmit();
    toggleAllErrors({ value: false });
    saveStateToSessionStorage();
    biLogger.addressInformationContinue({
      locationGuid: restaurant.currentLocationId,
      comment: selectedAddressOption?.comment || '',
      dispatchTime,
      dispatchTimeOption: dispatchTime ? 'later' : 'asap',
      dispatchType,
      curbsidePickupToggle: isCurbside,
      curbsideAdditionalInformationContent: curbsideAdditionalInformation,
    });
  }

  function setFieldErrorAndBi({ errorType }: { errorType: DeliveryFormField }) {
    setFieldError({ error: errorType, value: true });
    biLogger.addressInformationContinueValidationError({
      locationGuid: restaurant.currentLocationId,
      comment: selectedAddressOption?.comment || '',
      dispatchTime,
      dispatchTimeOption: dispatchTime ? 'later' : 'asap',
      dispatchType,
      errorReason: errorType as string,
    });
  }

  function renderDelivery() {
    return (
      <React.Fragment>
        <div className={styles.field}>
          <Text tagName="label" typography="p2-s" className={styles.label}>
            {t('checkout_main_delivery_address')}
          </Text>
          <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewAddress}>
            {address.formatted}
          </Text>
        </div>

        {selectedAddressOption && (
          <>
            <div className={styles.field}>
              <AddressInformationInput
                address={selectedAddressOption}
                field="addressLine2"
                onChange={setDeliveryAddressField}
                label={t('checkout_main_delivery_aptfloor.label')}
                data-hook={dataHooks.addressInformationLine2}
                className={styles.additionalAddressInformation}
                placeholder={t('checkout_main_deliverymethod_modal.addaddress.addinfo.placeholdertext')}
                errorMessage={
                  errorsVisibility.addressLine2 && fieldsErrors.addressLine2
                    ? t('checkout_main_delivery_contactinfo_mandatoryfield_errormessage')
                    : ''
                }
              />
            </div>
            <div className={styles.field}>
              <AddressInformationInput
                address={selectedAddressOption}
                field="label"
                onChange={setDeliveryAddressField}
                label={t('checkout_main_deliverymethod_modal.addaddress.label.title')}
                data-hook={dataHooks.addressInformationLabelField}
                placeholder={t('checkout_main_deliverymethod_modal.addaddress.label.placeholdertext')}
              />
            </div>
          </>
        )}
        <div className={styles.field}>
          <label>
            <CommentInput
              onChange={(newComment: string) => setDeliveryAddressField({ addressField: 'comment', value: newComment })}
              value={selectedAddressOption?.comment || ''}
              content={t('checkout_main_instructions_link')}
              dataHook={dataHooks.addressInformationComment}
              textClassName={styles.addressInformation_textArea}
              buttonClassName={styles.commentButton}
            />
          </label>
        </div>
      </React.Fragment>
    );
  }

  function renderTakeout() {
    return (
      <React.Fragment>
        <div className={styles.field}>
          <Text tagName="label" typography="p2-s" className={styles.label}>
            {isCurbside ? t('checkout_main_pickup_location_curbside') : t('checkout_main_pickup_location')}
          </Text>
          <div className={styles.pickupAddress}>
            <Text tagName="p" typography="p2-s" data-hook={dataHooks.addressInformationViewPickupLocation}>
              {formattedAddressWithComment}
            </Text>
            <TextButton
              priority={TEXT_BUTTON_PRIORITY.link}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(restaurant.address.formatted)}`,
                );
                biLogger.pickupShowOnMapClick({
                  locationGuid: restaurant.currentLocationId,
                  pageName: 'checkout',
                });
              }}
              data-hook={dataHooks.addressInformationShowMap}
              className={styles.showOnMapLink}
            >
              {t('order_settings_modal_showmap_cta')}
            </TextButton>
          </div>
        </div>
        {isCurbside && curbsideAdditionalInformation && (
          <div className={styles.field}>
            <Text tagName="label" typography="p2-s" className={styles.label}>
              Outfit Description
            </Text>
            <div className={styles.pickupAddress}>
              <Text tagName="p" typography="p2-s" data-hook={dataHooks.addressInformationViewCurbsideOutfitInfo}>
                {curbsideAdditionalInformation}
              </Text>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  function renderDineIn() {
    return (
      <React.Fragment>
        <div className={styles.field}>
          <Text tagName="label" typography="p2-s" className={styles.label}>
            {t('Checkout_main_deliverymethod_DineIn_Location_Text')}
          </Text>
          <div className={styles.pickupAddress}>
            <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewDineInLocation}>
              {formattedAddressWithComment}
            </Text>
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.pickupAddress}>
            <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewDineInInstructions}>
              {dineInInfo?.instructions}
            </Text>
          </div>
        </div>
        <div>
          <Text tagName="label" typography="p2-s" className={styles.label}>
            {dineInInfo?.label}
          </Text>
          <div className={styles.pickupAddress}>
            <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewDineInLabelValue}>
              {initialTableNumber}
            </Text>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div
      data-hook={dataHooks.addressInformationView}
      className={styles.wrapper}
      aria-labelledby={addressInformationTitleId}
      aria-describedby={describedby}
    >
      <CheckoutFlowStepTitle
        text={t('checkout_main_delivery_method')}
        done={done}
        collapsed={collapsed}
        index={index}
        onEdit={onEdit}
        action={{
          onAction: () => {
            biLogger.dispatchChangeClick({ pageName: 'checkout' });
            openModal({
              modal: Modals.ADDRESS_INFORMATION_MODAL,
              context: {
                origin: 'checkout',
              },
            });
          },
          label: 'Change',
          dataHook: dataHooks.addressInformationChangeAction,
        }}
        editButtonDataHook={dataHooks.checkoutSummaryLineEditAddress}
        titleId={addressInformationTitleId}
      />

      {!done && !collapsed && (
        <form
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            handleSubmit();
          }}
          data-hook={dataHooks.addressInformationForm}
        >
          {dispatchType === 'delivery' && renderDelivery()}
          {dispatchType === 'takeout' && renderTakeout()}
          {dispatchType === 'dine-in' && renderDineIn()}

          {dispatchType !== 'dine-in' && (
            <React.Fragment>
              <Text tagName="label" typography="p2-s" className={styles.label}>
                {dispatchType === 'delivery' ? t('checkout_main_delivery_time') : t('checkout_main_pickup_time')}
              </Text>
              <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewTimeLine}>
                {timing}
              </Text>
            </React.Fragment>
          )}
          {errorOrderItem && getErrorKey(errorOrderItem) && (
            <SectionNotification type="error" className={styles.error} data-hook={dataHooks.addressInformationError}>
              <SectionNotification.Icon icon={<Error />} />
              <SectionNotification.Text>
                <Trans
                  t={t}
                  i18nKey={getErrorKey(errorOrderItem)}
                  components={[
                    <Link data-hook={dataHooks.addressInformationErrorLink} to="/cart">
                      placeholder
                    </Link>,
                  ]}
                />
              </SectionNotification.Text>
            </SectionNotification>
          )}

          {!isMinOrderPriceMet && (
            <SectionNotification
              type="error"
              className={styles.error}
              data-hook={dataHooks.addressInformationMinOrderPriceErrorBanner}
            >
              <SectionNotification.Icon icon={<Error />} />
              <SectionNotification.Text>
                <Trans
                  t={t}
                  i18nKey={'checkout_main_order_minprice_errormessage_with_link'}
                  components={[
                    displayableAmountLeft,
                    <Link data-hook={dataHooks.addressInformationMinOrderPriceErrorBannerLink} to="/">
                      placeholder
                    </Link>,
                  ]}
                />
              </SectionNotification.Text>
            </SectionNotification>
          )}

          <Button
            upgrade
            fullWidth
            priority={PRIORITY.primary}
            className={styles.button}
            data-hook={dataHooks.addressInformationContinue}
            type="submit"
            disabled={!!errorOrderItem || !isMinOrderPriceMet}
          >
            <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
          </Button>
        </form>
      )}

      {done && (
        <AddressInformationSummary
          dineInInfo={dineInInfo}
          address={dispatchType === 'delivery' ? address : restaurant.address}
          dispatchType={dispatchType}
          dispatchTime={dispatchTime}
          timezone={timezone}
          locale={locale}
          tableNumber={initialTableNumber}
          deliveryInfos={deliveryInfos}
          idealDeliveryArea={idealDeliveryArea}
          isCurbsideToggledOn={isCurbside}
        />
      )}
    </div>
  );
};

AddressInformationView.displayName = 'AddressInformationView';

export default AddressInformationView;
