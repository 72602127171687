import React from 'react';
import { Element } from 'react-scroll';
import { DisplayableSubMenu, MenuDisplayOptions } from '@wix/restaurants-client-logic';
import Text from '../../core-components/Text';
import SectionView from './SectionView';
import dataHooks from '../../data-hooks';
import MenuViewEmptyState from '../MenuViewEmptyState';
import SectionTabs from '../SectionTabs';
import styles from './SubMenuView.scss';
import { ClickOnMenuItemPayload } from '../../../../state/cart/cart.actions.types';

interface SubMenuViewProps {
  displayableSubMenu: DisplayableSubMenu;
  isMobile?: boolean;
  layout?: 'side-by-side' | 'card';
  menuDisplayOptions?: MenuDisplayOptions;
  isRTL?: boolean;
  selectAddressOnDishClick?: boolean;
  clickOnMenuItem: (payload: ClickOnMenuItemPayload) => void;
}

const SubMenuView: React.FC<SubMenuViewProps> = ({
  displayableSubMenu,
  isMobile,
  layout,
  menuDisplayOptions,
  isRTL,
  selectAddressOnDishClick,
  clickOnMenuItem,
}) => {
  const hasSections = displayableSubMenu.sections.length > 0;
  const showMenuDescription = !menuDisplayOptions?.hideMenuDescription;

  return (
    <Element name={displayableSubMenu.id} className={styles.wrapper}>
      <section id={`restaurants-orders-entity-${displayableSubMenu.id}`} tabIndex={-1}>
        <div className={styles.titlesWrapper}>
          <Text
            typography="header-s"
            tagName="h2"
            data-hook={dataHooks.menusSubMenuTitle}
            fontSizeOverride="menu-title"
          >
            {displayableSubMenu.displayableTitle}
          </Text>
          {showMenuDescription && displayableSubMenu.displayableDescription && (
            <Text typography="p2-m" tagName="p" fontSizeOverride="menu-description" className={styles.description}>
              {displayableSubMenu.displayableDescription}
            </Text>
          )}
        </div>

        {!hasSections && <MenuViewEmptyState id={displayableSubMenu.id} />}

        {hasSections && (
          <div>
            {isMobile && displayableSubMenu.sections.length > 1 && (
              <SectionTabs
                sections={displayableSubMenu.sections.map((v) => ({
                  id: v.id,
                  slug: v.slug,
                  title: v.displayableTitle,
                }))}
                isRTL={isRTL}
                dataHook={dataHooks.sectionTabs(displayableSubMenu.id)}
                isMobile
                className={styles.sectionTabs}
              />
            )}
            {displayableSubMenu.sections.map((displayableSection, i) => (
              <SectionView
                clickOnMenuItem={clickOnMenuItem}
                key={displayableSection.id}
                displayableSection={displayableSection}
                isMobile={isMobile}
                layout={layout}
                menuDisplayOptions={menuDisplayOptions}
                last={i === displayableSubMenu.sections.length - 1}
                selectAddressOnDishClick={selectAddressOnDishClick}
              />
            ))}
          </div>
        )}
      </section>
    </Element>
  );
};

SubMenuView.displayName = 'SubMenuView';

export default SubMenuView;
