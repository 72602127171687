import { Tip } from '@wix/restaurants-client-logic';

export const defaultTips = {
  tipOption: 'percentageValues',
  percentageValues: [
    { value: 'None', isDefault: true },
    { value: 5, isDefault: false },
    { value: 10, isDefault: false },
    { value: 15, isDefault: false },
  ],
  currencyValues: [
    { value: 'None', isDefault: true },
    { value: 2, isDefault: false },
    { value: 4, isDefault: false },
    { value: 6, isDefault: false },
  ],
  customTips: true,
};

export const getTipValue = (
  selectedTipValue: number | string | undefined,
  currencySymbol: string,
  tip: Tip | undefined,
) => {
  return tip?.isCustom
    ? 'custom'
    : selectedTipValue === 'None'
    ? 'none'
    : tip?.tipType === 'percent'
    ? `${selectedTipValue}%`
    : `${currencySymbol}${selectedTipValue}`;
};
